<template>
  <div>
    <div class="relative mt-2">
       <div class="grid grid-cols-12 gap-6">
          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="Loading"> 
              <LoadingIcon icon="three-dots" class="w-12 h-12 mt-20" />
          </div>
          <div class="col-span-12 xl:col-span-12 xxl:col-span-12 z-10" v-else>
              <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3 mb-5">
                <!-- Filter -->
                <div class="w-60 mr-2">
                  <select name="filterCatSel" class="form-select cursor-pointer" @change="Filter($event)"> /
                      <option value="all" :selected="CurTag === 'all'">{{ $t('All Categories') }} <font v-if="Counter['all']">({{ Counter['all'] }})</font></option>
                      <option v-for="t in tags" :value="t.tag" :key="t.tag" :selected="CurTag === t.tag">{{ t.name }} ({{ Counter[t.tag] }})</option>
                  </select>
                </div>
                <!-- Actions -->
                <div class="mr-2"><button class="btn btn-outline-secondary w-50 md:w-40 inline-block" @click="CheckAll()" v-if="seeds.length">{{ $t('Select all') }}</button></div>
                <div class="mr-2">
                  <span class="w-50 inline-block mr-1 text-lg font-medium p-1.5 align-top ml-10" v-if="CountCheck()">{{ CountCheck() }}</span>
                  <a class="btn btn-primary mr-1 mb-2 ml-3" v-if="CountCheck()"  @click="showModal('modal-tag')">
                    <ImageIcon class="w-4 h-4 mr-2" /> {{ $t('Assign Category') }}
                  </a>
                  <a class="btn btn-danger mr-1 mb-2 ml-3" v-if="CountCheck()" @click="showModal('modal-delete')">
                    <TrashIcon class="w-4 h-4 mr-2" /> {{ $t('Delete') }}
                  </a>
                </div>
                <!-- Pagination -->
                <ul class="pagination ml-auto mr-2">
                  <li class="w-12"><button class="pagination__link" @click="InitPage()" :disabled="!pagination.prev"><ChevronsLeftIcon class="w-4 h-4" /></button></li>
                  <li class="w-12">
                    <button class="pagination__link" @click="PrevPage()" :disabled="!pagination.prev"><ChevronLeftIcon class="w-4 h-4" /></button>
                  </li>
                  <li><span class="pagination__link cursor-default" v-if="pagination.pages>2 && (pagination.currentPage+1)>2">...</span></li>
                  <li v-for="(n) in pagination.pages" :key="n">
                      <a class="pagination__link pl-0 pr-0 cursor-default" :class="isCurPage(n)" v-if="n<((pagination.currentPage+1)+2) && n>((pagination.currentPage+1)-2)">{{ n }}</a>
                  </li>
                  <li><span class="pagination__link cursor-default" v-if="pagination.pages>2 && (pagination.currentPage+1)<(pagination.pages-1)">...</span></li>
                  <li class="w-12">
                    <button class="pagination__link" @click="NextPage()" :disabled="!pagination.next"><ChevronRightIcon class="w-4 h-4" /></button>
                  </li>
                  <li class="w-12"><a class="pagination__link" @click="EndPage()" v-if="pagination.next && pagination.toend"><ChevronsRightIcon class="w-4 h-4" /></a></li>
                </ul>
                <select class="w-20 form-select box mt-3 sm:mt-0 mr-3 ml-3" v-model="pagination.perPage" @change="SetPerPage($event)">
                  <option v-bind:value="50">50</option>
                  <option v-bind:value="125">125</option>
                  <option v-bind:value="250">250</option>
                </select>
              </div>
              <!-- Results -->
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-20" v-if="LoadSeeds"><LoadingIcon icon="three-dots" class="w-12 h-12" /></div> 
              <div v-else>
                <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center text-center mt-20" v-if="!seeds.length">
                  <InfoIcon class="w-20 h-20 mb-5" /><br />{{ $t('No images have been registered') }}.<br />{{ $t('Start the monitor in training mode and return') }}.
                </div> 
                <div class="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-1 mr-3" v-else>
                  <div v-for="(seed) in seeds" v-bind:key="seed" class="intro-y col-span-6 sm:col-span-4 md:col-span-3 xxl:col-span-2">
                    <div class="file box rounded-md px-5 pt-2 pb-5 px-3 sm:px-5 relative ">
                      <div class="intro-y flex items-center h-10 m-0 p-0">
                        <input class="form-check-input border border-gray-900" v-model="selectedSeeds" :value="seed.id" type="checkbox" /> 

                        <img :id="seed.id" :src="'data:image/png;base64,'+ seed.image64" :title="$t('Zoom in')" 
                         class="w-6 inline-flex ml-auto border border-gray-300 align-top p-0.5 bg-white cursor-pointer" @click="showzoom(seed)"  />
                         
                      </div>
                      <div class="block left-0 top-0 ml-3 items-center cursor-pointer" @click="CheckSeed(seed.id)">
                        <a class="w-3/5 file__icon file__icon--image mx-auto">
                          <div class="file__icon--image__preview image-fit">
                            <img v-bind:src="'data:image/png;base64,'+ seed.image64" class="w-full" v-if="seed.image64">
                          </div>
                        </a>
                        <div class="text-gray-600 dark:text-gray-600 text-xs text-center mt-2">{{ seed.id }}</div>
                        <div class="text-gray-600 text-xs text-center mt-1 font-bold" v-bind:style="{'color': seed.tagName.color }">
                          {{ seed.tagName.name }} 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal -->
              <div
                id="modal-tag"
                class="modal"
                tabindex="-1">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h2 class="font-medium text-base mr-auto">{{ $t('Categorization') }}</h2>
                      <button class="btn btn-outline-secondary hidden sm:flex cursor-default">
                        {{ selectedSeeds.length }} {{ $t('selected images') }}
                      </button>
                    </div>
                    <div class="modal-body p-5 pt-3">
                      <div class="font-medium mb-5 mt-0">{{ $t('Select the category you want to assign') }}:</div>
                      <button class="btn btn-elevated-secondary w-full lg:w-64 lg:mr-4 mb-3 lg:ml-3" v-for="(t) in tagsAll" :key="t.tag" @click="CategorizeTag(t.tag)" :class="isCatTag(t.tag)">{{ t.name }}</button>
                    </div>
                    <div class="modal-footer text-right">
                      <button id="dismiss-modal-tag" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1">{{ $t('Cancel') }}</button>
                      <button type="button" class="btn btn-primary w-32" :disabled="!CatTag" @click="Categorize()">{{ $t('Confirm') }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="modal-delete"
                class="modal"
                tabindex="-1"
                aria-hidden="true">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                    <div class="modal-body p-10 text-center">
                      <div class="p-5 text-center">
                          <ActivityIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
                          <div class="text-2xl mt-5">{{ $t('Are you sure you want to delete') }}<br> {{ selectedSeeds.length }} {{ $t('selected images') }}?</div>
                          <div class="text-gray-600 mt-2">
                              {{ $t('This action is irreversible') }}. 
                          </div>
                      </div>
                      <div class="px-5 pb-8 text-center">
                          <button id="dismiss-modal-delete" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                          <button type="button" class="btn btn-danger w-24" @click="Delete()">{{ $t('Continue') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="modal-zoom"
                class="modal"
                tabindex="-1">
                <div class="modal-dialog modal-xl modal-zoom">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h2 class="font-medium text-base mr-auto" id="modal-zoom-title"></h2>
                      <div id="modal-zoom-tag"></div>
                    </div>
                    <div class="modal-body p-5 pt-3">
                     <img id="modal-zoom-image">
                    </div>
                  </div>
                </div>
              </div>
              <!-- Notifications -->
              <div id="success-categorize" class="toastify-content hidden flex">
                  <CheckCircleIcon class="text-theme-10 w-12 h-12" />
                  <div class="ml-4 mr-4">
                      <div class="font-medium">{{ $t('Complete Categorization') }}</div>
                      <div class="text-gray-600 mt-1">{{ $t('Have been updated') }} {{ selectedSeeds.length }} {{ $t('Images') }}</div>
                  </div>
              </div>
              <div id="success-delete" class="toastify-content hidden flex">
                  <CheckCircleIcon class="text-theme-10 w-12 h-12" />
                  <div class="ml-4 mr-4">
                      <div class="font-medium">{{ $t('Removal completed') }}</div>
                      <div class="text-gray-600 mt-1">{{ $t('Have been removed') }} {{ selectedSeeds.length }} {{ $t('Images') }}</div>
                  </div>
              </div>
          </div> 
       </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref,  onMounted, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import Toastify from "toastify-js";
import cash from "cash-dom";
import { useI18n } from 'vue-i18n'
/*import firebase from 'firebase/app'
import 'firebase/firestore'
*/
import 'firebase/storage'


import { _firebase as $_firebase } from "@/model/firebase";
//import { getStorage } from "firebase/storage"; 


export default defineComponent({
  setup() {

    const router        = useRouter();
    const Loading       = ref(true)
    const LoadSeeds     = ref(true)
    const seeds         = ref([]);
    const tags          = ref([]);
    const tagsAll       = ref([]);
    const {t}           = useI18n(); 
    const pagination    = ref({
                                currentPage: 0,
                                perPage: 50,
                                pages: 1,
                                total: 0,
                                init:  null,
                                first: null,
                                last:  null,
                                prev:  false,
                                next:  true,
                                toend: true
                              })     
    const colors        = ref([]);     
    const tagColor      = ref([ { tag: 1, color: "#1a6d29" },
                                { tag: 2, color: "#f0ae00" }, 
                                { tag: 3, color: "#ff8000" }, 
                                { tag: 4, color: "#5d5d5d" },
                                { tag: 5, color: "#cb3234" },
                                { tag: 6, color: "#c81d11" },
                                { tag: 7, color: "#f70000" },
                              ]);   
    const selectedSeeds  = ref([]);  
    const selectedTags   = ref([{ id: '' , name: t('All Categories') }]);  
    const CurTag         = ref('all');     
    const Counter        = ref([]); 
    const qry            = ref({ param:     'date', 
                                 condition: '!=', 
                                 val:       '', 
                                 order:     "date", 
                                 type:      "desc" 
                               })   
    const CatTag         = ref();        
    
    const showzoom       = (seed) => { 
      cash("#modal-zoom-title").html(""); 
      cash("#modal-zoom-image").attr("src",""); 
      cash("#modal-zoom-title").html(seed.id); 
      cash("#modal-zoom-image").attr("src",seed.gimage); 
      cash("#modal-zoom-tag").html(seed.tagName.name); 
      cash("#modal-zoom").modal("show");  
    };

    const getTotalSeeds = async () => {  
        pagination.value.total = 0; Counter.value = [];
        await $_firebase.database().ref('image_counter').once("value", async function(snapshot) {
          
          var res = snapshot.val();

          for(let index in res){
            let element = res[index];
            pagination.value.total += element ; 
            Counter.value[index] = element; 
          }
          /*await snapshot.val().forEach( (n,index) => { 
            console.log(n)
            console.log(index)
            //pagination.value.total += n ; 
            //Counter.value[index] = n; 
          });*/ 
          
          Counter.value['all'] = pagination.value.total;
          pagination.value.toend = false
          Loading.value          = false;
        }, function (errorObject) { console.log("The read db failed: " + errorObject.code); });
    }

    const initSeeds = async () => {
      LoadSeeds.value = true;
      seeds.value    = []; selectedSeeds.value = []; //CatTag.value = null
      const first    = $_firebase.firestore().collection('image')
                               .where(qry.value.param,qry.value.condition,qry.value.val)
                               .orderBy(qry.value.order, qry.value.type)
                               .limit(pagination.value.perPage); 
      let snap       = await first.get()
      pagination.value.currentPage = 0; 
      pagination.value.first   = pagination.value.init = snap.docs[0];
      pagination.value.last    = snap.docs[snap.docs.length -1];
      first.get().then(snapshot => {
                        snapshot.forEach(async doc => {
                          renderSeed(doc);
                        });
                        SetPaginationControls();
                      });
      if(!seeds.value.length)LoadSeeds.value = false;
    };

    const PrevPage = async () => {
      seeds.value    = []; selectedSeeds.value = []; CatTag.value = null
      pagination.value.prev = pagination.value.next = false;
      const prev  = $_firebase.firestore().collection('image')
                            .where(qry.value.param,qry.value.condition,qry.value.val)
                            .orderBy(qry.value.order, qry.value.type)
                            .endBefore(pagination.value.first)
                            .limitToLast(pagination.value.perPage);
      let snap    = await prev.get()
      pagination.value.first  = snap.docs[0];
      pagination.value.last   = snap.docs[snap.docs.length - 1];
      pagination.value.currentPage--;
      prev.get().then(snapshot => { SetPaginationControls(); snapshot.forEach(async doc => { renderSeed(doc); });  });
    }

    const NextPage = async () => {
      seeds.value    = []; selectedSeeds.value = []; CatTag.value = null
      pagination.value.prev = pagination.value.next = false;
      const next  = $_firebase.firestore().collection('image')
                            .where(qry.value.param,qry.value.condition,qry.value.val)
                            .orderBy(qry.value.order, qry.value.type)
                            .startAfter(pagination.value.last)
                            .limit(pagination.value.perPage)
      let snap    = await next.get()
      pagination.value.first  = snap.docs[0];
      pagination.value.last   = snap.docs[snap.docs.length - 1];
      pagination.value.currentPage++; 
      next.get().then(snapshot => { SetPaginationControls(); snapshot.forEach(async doc => { renderSeed(doc);}); });
    }

    const InitPage = async () => {
      seeds.value    = []; selectedSeeds.value = []; CatTag.value = null
      const init  = $_firebase.firestore().collection('image')
                            .where(qry.value.param,qry.value.condition,qry.value.val)
                            .orderBy(qry.value.order, qry.value.type)
                            .startAt(pagination.value.init)
                            .limit(pagination.value.perPage)
      let snap    = await init.get()
      pagination.value.first  = snap.docs[0]
      pagination.value.last   = snap.docs[snap.docs.length - 1]
      pagination.value.currentPage = 0; 
      init.get().then(snapshot => { SetPaginationControls(); snapshot.forEach(async doc => { renderSeed(doc);}); })
    }

    const EndPage = async () => {
      seeds.value    = []; selectedSeeds.value = []; CatTag.value = null
      const end  = $_firebase.firestore().collection('image')
                          .where(qry.value.param,qry.value.condition,qry.value.val)
                          .orderBy(qry.value.order, qry.value.type)
                          .endBefore(pagination.value.end)
                          .limitToLast((pagination.value.perPage-1))
      let snap    = await end.get()
      pagination.value.first  = snap.docs[0];
      pagination.value.last   = snap.docs[snap.docs.length - 1];
      pagination.value.currentPage = pagination.value.pages-1; 
      end.get().then(snapshot => {  SetPaginationControls(); snapshot.forEach(async doc => { renderSeed(doc);});  renderSeed(pagination.value.end);});
    }

    const SetPerPage = (num) => {
      pagination.value.perPage = num.target.value;
      initSeeds()
    }

    const SetPaginationControls = () => {
      pagination.value.pages = Math.ceil(pagination.value.total/pagination.value.perPage);
      if((pagination.value.currentPage+1)==pagination.value.pages){ pagination.value.next = false; }else{ pagination.value.next = true; }
      if(pagination.value.currentPage==0){ pagination.value.prev = false; }else{ pagination.value.prev = true;}
    }

    const isCurPage = (p) => { if(p==(pagination.value.currentPage+1))return "pagination__link--active" }
    
    const renderSeed = async (s) => {
     let item      = s.data()
     let tagName   = ""; 
     let TagData   = await getTag(item.tag)
     if(TagData){ tagName = TagData; if(!selectedTags.value[item.tag])selectedTags.value[item.tag] = { id: TagData.tag , name: TagData.name }  }
     let img_base64_val  = null
     if(item.image_data._delegate._byteString.binaryString)img_base64_val = btoa(item.image_data._delegate._byteString.binaryString);
     let imageUrl     = item.uri;
     if (item.uri.startsWith('gs://'))imageUrl = await getGoogleLink(item.uri)
     seeds.value.push({ 
        id:       s.id, 
        name:     item.name,
        gimage:   imageUrl,
        image64:  img_base64_val,
        tag:      item.tag,  
        tagName:  tagName,
     });
      
    }

    const getGoogleLink = async (uri) => {
      try {
        const url = await $_firebase.storage().refFromURL(uri).getDownloadURL();
        return url;
      } catch (error) {
        console.log("getGoogleLink error: "+error);
      }
    }
  
    const initTagsColors = async () => {
        tagColor.value.forEach(t => { colors.value[t.tag] = t.color; });
    }

    const initTags = async () => {
    await $_firebase.firestore().collection('tag').get() 
      .then(snapshot => {
        snapshot.forEach(doc => {
          let item = doc.data()
          let tagColor = colors.value[doc.id];
          let tag = { tag:   doc.id, name:  item.name, color: tagColor, score: 0 }
          if(Counter.value[doc.id])tags.value.push(tag);
          tagsAll.value.push(tag);
        });
        
      });
    }

    const getTag = async (tagId) => { let rtag = false; tags.value.forEach((t,index) => { t.indx = index; if(t.tag==tagId)rtag = t; }); return rtag; }
    
    const Filter = (evt) => {
      if(evt.target.value){ CurTag.value = evt.target.value; }else{ CurTag.value = 'all'; }
      FilterbyTag()
    }

    const FilterbyTag = async () => {
      //console.log('Quedan '+Counter.value[CurTag.value]+' de la cat actual:'+CurTag.value)
      if(!Counter.value[CurTag.value]){ /*tags.splice(CurTag.value, 1);*/ CurTag.value = "all";  }
      qry.value.param     = 'tag'; 
      qry.value.condition = '=='; 
      qry.value.val       = CurTag.value;
      if(CurTag.value=='all')qry.value = { param: 'date', condition: '!=', val: '', order: "date", type: "desc" }
      if(Counter.value[CurTag.value])pagination.value.total = Counter.value[CurTag.value];
      await initSeeds()
    }

    const CheckAll = () => {
      selectedSeeds.value = [];
      seeds.value.forEach(function (s) { selectedSeeds.value.push(s.id); });
    }

    const CountCheck = () => {
      let sel = selectedSeeds.value.length;
      if(selectedSeeds.value.length)return sel+" "+t('selected');
    }
    
    const CheckSeed = async (s) => {
      let exists = false
      selectedSeeds.value.findIndex(function(seed, index) { if(seed == s){ exists = true; if (index > -1)selectedSeeds.value.splice(index, 1); } });
      if(!exists)selectedSeeds.value.push(s) 
    }

    const CategorizeTag = (t) => { CatTag.value = t; }

    const isCatTag = (t) => { if(CatTag.value==t)return "btn-elevated-dark"; } 
    
    const getCurTag = (sID) => { let seedtag = false; seeds.value.forEach((s) => { if(s.id==sID)seedtag = s.tag; }); return seedtag;  }

    const getTagCounter = async (tag) => { let c = 0; await $_firebase.database().ref('image_counter/'+tag).once("value", function(snapshot) { c = snapshot.val(); }); return c;  }

    const setAddTagCounter = async (tag) => {
        await $_firebase.database().ref('image_counter/'+ tag).set($_firebase.firebase().database.ServerValue.increment(1)).then( async function()  {
                                                                      Counter.value[tag] = await getTagCounter(tag);
                                                                      console.log('actualizamos image_counter para tag '+tag+" con valor: "+Counter.value[tag])
                                                                    });     
    }
    const setDelTagCounter = async (tag) => {
        await $_firebase.database().ref('image_counter/'+ tag).set($_firebase.firebase().database.ServerValue.increment(-1)).then(async function() {
                                                                      Counter.value[tag] = await getTagCounter(tag);
                                                                      console.log('actualizamos image_counter para tag '+tag+" con valor: "+Counter.value[tag])
                                                                    });     
    }

    const Categorize  =  async () => {
      cash(".modal").addClass("hidden")
      Toastify({
        node: cash("#success-categorize").clone().removeClass("hidden")[0],
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: false
      }).showToast();
      Loading.value = true;
      let cnt = 1;
      for (const s of selectedSeeds.value) {
        let currTag   = getCurTag(s);
        let tagCount  =  await getTagCounter(currTag);
        let nextTag    = CatTag.value;
        let nextCount  = await getTagCounter(nextTag);
        
        if(currTag!=nextTag){
          console.log(cnt+' Valor '+s+' con tag '+currTag+'->'+tagCount+' / '+'Nuevo tag: '+nextTag+'->'+nextCount)
          await $_firebase.firestore().collection('image')
                      .doc(s)
                      .update({ tag: CatTag.value })
                      .then( async() => {
                        setDelTagCounter(currTag);
                        setAddTagCounter(nextTag);
                        console.log('categorizada image '+s+' con el tag: '+nextTag)
                      })
        }
        cnt++;              
      }  
      await getTotalSeeds()
      cash(".main").removeClass("overflow-y-hidden")
      FilterbyTag();
    }

    const Delete  = async () => {
      cash(".modal").addClass("hidden")
      Toastify({
        node: cash("#success-delete").clone().removeClass("hidden")[0],
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        stopOnFocus: false
      }).showToast();
      Loading.value = true;
      for (const s of selectedSeeds.value) {
        let currTag   = getCurTag(s);
        let tagCount  = await getTagCounter(currTag);
        console.log('Valor '+s+' con tag '+currTag+'->'+tagCount)
        await $_firebase.firestore().collection('image')
                .doc(s)
                .delete()
                .then( async() => {
                  console.log("Se ha eliminado "+s+" de firestore");
                  await setDelTagCounter(currTag)
                })
      }  
      await getTotalSeeds()
      cash(".main").removeClass("overflow-y-hidden")
      FilterbyTag();
    }

    const showModal = (m) => {
      cash("#"+m).modal("show");
    };

    const linkTo = (page) => {
      cash(".side-nav").removeClass("hidden")
      cash(".top-bar-boxed ").removeClass("hidden")
      router.push({
        name: page
      });
    };

    onBeforeMount( async () => {
      await getTotalSeeds()
      initTagsColors()
      await initTags()
    });

    onMounted( async () => {
      initSeeds()
      if (process.client && window) {
        window.history.scrollRestoration = 'auto';
      }
      
    });

    return {
      Loading,
      LoadSeeds,
      seeds,
      tags,
      tagsAll,
      pagination,
      InitPage,
      PrevPage,
      NextPage,
      EndPage,
      SetPerPage,
      isCurPage,
      selectedTags,
      selectedSeeds,
      CurTag,
      Filter,
      Counter,
      CheckAll,
      CountCheck,
      CheckSeed,
      CatTag,
      isCatTag,
      Categorize,
      CategorizeTag,
      showModal,
      showzoom,
      Delete,
      linkTo,
    };
  },
});
</script>
<style>
.modal-zoom{ width: 65% !important;}
#modal-zoom-tag{ padding: 1px 20px;border: 1px solid #ccc;}
.cursor-default { cursor: default !important; }
.main{ padding-right: 25px !important;}
</style>
