<template>
  <div class="intro-y box mt-5 lg:mt-0">
    <div class="relative flex items-center p-5">
      <div class="w-10 h-10 image-fit">
        <div class="dropdown-toggle w-10 h-10 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 bg-theme-17 text-center pt-0.5" role="button" aria-expanded="false">
          <span class="text-white text-2xl" v-if="User.displayName">{{ User.displayName.charAt(0) }}</span>
        </div>
      </div>
      <div class="ml-4 mr-auto">
        <div class="font-medium text-base" v-if="User.displayName"> {{ User.displayName }} </div>
        <div class="text-gray-600" v-if="User.email">{{ User.email.substring(0,20) }}<span :class="User.email.length > 20 ? '' : 'hidden'">...</span></div>
      </div>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5 pt-0">
      <a class="flex items-center mt-5 cursor-pointer" v-for="(tab) in Tabs" :key="tab.id" :class="TabActive(tab.id)" @click="ChangeTab(tab.id)">
        <component :is="tab.icon" class="w-4 h-4 mr-2" /> 
        {{ tab.name }}
      </a>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5">
      <a class="flex items-center cursor-pointer" :class="TabActive('ManageEvents')" @click="ChangeTab('ManageEvents')"><ActivityIcon class="w-4 h-4 mr-2" /> {{ $t('My Activity') }}</a>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5 flex">
      <button @click="Logout" type="button" class="btn btn-secondary py-1 px-2">{{ $t('Logout') }}</button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, useContext } from "vue";
import { useStore } from "@/store";
import { event as $event } from "@/model/events";
import { useI18n } from 'vue-i18n'
import { _firebase as $_firebase } from "@/model/firebase";

export default defineComponent({
  props: {
    tab: {
      type: String,
      default: 'profile',
    }
  },
  setup(context, props) {

    const store   = useStore();   
    const User    = computed(() => store.state.main.User );
    const {t}     = useI18n(); 
    const Tabs    = ref([
                        { id: 'welcome',  name: t('Dashboard'), icon: 'UserIcon' },
                        { id: 'profile',  name: t('Personal information'), icon: 'EditIcon' },
                        { id: 'password', name: t('Password'), icon: 'LockIcon' }
                        ])
    const CurTab  = ref()
    
    const TabActive = (t) => {
      if(t==CurTab.value)return "dark:text-white text-theme-12 font-medium"
    };

    const Logout = async () => {
        await $event.saveEvent('user.logout',{ uid: User.value.uid }, false);
        $_firebase.auth().signOut().catch(err => alert(err.message));
    }

    const ChangeTab = (t) => { CurTab.value = t  };
  
    onMounted( () => { 
      useContext().expose({ CurTab, ChangeTab })
      if(context.tab)CurTab.value = context.tab;
    });

    return {
      props,
      context,
      User,
      Tabs,
      CurTab,
      TabActive,
      ChangeTab,
      Logout
    };
  }
});
</script>
