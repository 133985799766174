<template>
  <div>
    <div class="grid chat grid-cols-12 gap-6">
      <div class="col-span-12 lg:col-span-5 xxl:col-span-3">
        <div class="intro-y pr-1">
          <div class="box p-2">
            <div class="chat__tabs nav nav-tabs justify-center" role="tablist">
              <a
                id="dataset-tab"
                data-toggle="tab"
                data-target="#dataset"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center" :class="curDataset ? 'active' : ''" 
                role="tab"
                aria-controls="dataset"
                aria-selected="true"
                v-on:click="curModel=false; curDataset=datasets[0].id"
                ><ListIcon class="w-4 h-4 mr-1" />{{$t('Datasets')}}
                </a
              >
              <a
                id="model-tab"
                data-toggle="tab"
                data-target="#model"
                href="javascript:;"
                class="flex-1 py-2 rounded-md text-center" :class="curModel ? 'active' : ''" 
                role="tab"
                aria-controls="model"
                aria-selected="false"
                v-on:click="curDataset=false; curModel=models[0] ? models[0].modelID : ''"
                ><CpuIcon class="w-4 h-4 mr-1" />{{$t('Models')}}</a
              >
            </div>
          </div>
        </div>
        <div class="tab-content">
          <div id="dataset" class="tab-pane" :class="curDataset ? 'active' : ''"  role="tabpanel" aria-labelledby="dataset-tab">
            <div class="right-0 top-0 mr-5 mt-3" :class="createDataset ? 'hidden' : ''">
              <button type="button" class="btn btn-outline-secondary w-fill" @click="newDataset(true)"><PlusIcon class="w-4 h-4 mr-2" />{{$t('New dataset')}}</button>
            </div>
            <!-- Create Dataset --> 
            <div class="pr-1" :class="!createDataset ? 'hidden' : ''">
              <div class="box p-5 mt-5 mb-3">
                <form ref="newDataset" class="validate-form" @submit.prevent="addDataset('newDataset',$event)">
                  <div class="relative text-gray-700 dark:text-gray-300">
                    <input type="text" name="dsname" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10 " :placeholder="$t('Name')" required />
                    <SearchIcon class="w-4 h-4 hidden sm:absolute my-auto inset-y-0 mr-3 right-0" />
                  </div>
                  <select name="dstype" class="form-select mt-3 sm:mr-2 cursor-pointer bg-gray-200 w-full">
                      <option value="MULTILABEL">Multilabel</option>
                      <option value="MULTICLASS">Multiclass</option>
                  </select>
                  <button type="submit" class="btn btn-primary w-1/2 mt-3">{{$t('Add')}}</button>
                  <button type="button" class="btn btn-secondary w-1/2 mt-3" @click="closeAddDataset()">{{$t('Cancel')}}</button>
                 </form>
              </div>
            </div>
            <div class="scrollbar-hidden pr-1 pt-1" :class="datasets ? '' : 'hidden'">
              <div class="cursor-pointer box relative flex items-center p-5 mt-2" v-for="(dataset) in datasets" :key="dataset.id" :class="dataset.id==curDataset ? 'bg-gray-100' : ''">
                <div class="w-12 h-12 flex-none image-fit mr-1" @click="ChangeDataset(dataset.id)">
                  <div class="w-12 h-12 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 text-center pt-1.5 hover:bg-theme-17" 
                       :class="dataset.id==curDataset ? 'bg-theme-17' : 'bg-theme-21'"
                       role="button" aria-expanded="false">
                    <span class="text-white text-2xl">{{  dataset.displayName.charAt(0) }}</span>
                  </div>
                  <div class="w-3 h-3 bg-theme-10 absolute right-0 bottom-0 rounded-full border-2 border-white z-10"></div>
                </div>
                <div class="ml-2 overflow-hidden" @click="ChangeDataset(dataset.id)">
                  <div class="flex items-center">
                    <a class="font-medium truncate"  :class="dataset.id==curDataset ? 'text-theme-17' : ''">{{ dataset.displayName }}</a>
                  </div>
                  <div class="w-full truncate text-gray-600 mt-0.5">{{ dataset.id }}</div>
                </div>
                <div class="dropdown ml-auto">
                  <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false">
                    <MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300" />
                  </a>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer" @click="ChangeDataset(dataset.id)">
                        <EditIcon class="w-4 h-4 mr-2" />{{$t('Edit')}}
                      </a>
                      <!-- <a class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer">
                        <TrashIcon class="w-4 h-4 mr-2" />Eliminar
                      </a>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="model" class="tab-pane" :class="curModel ? 'active' : ''" role="tabpanel" aria-labelledby="model-tab" >
            <div class="pr-1">
              <div class="box p-5 mt-5 pt-0">
                <div v-if="models.length">
                  <div class="cursor-pointer flex items-center border-b border-gray-200 dark:border-dark-5 pb-5 pt-5" v-for="(model) in models" :key="model.modelID">
                    <div @click="ChangeModel(model.modelID)">
                      <div class="text-gray-600">{{ model.displayName }}</div>
                      <div class="mt-1">{{ model.modelID }}</div>
                    </div>
                    <CpuIcon class="w-8 h-8 ml-auto hover:text-theme-12" :class="model.modelID==curModel ? 'text-theme-12' : 'text-gray-600'" @click="ChangeModel(model.modelID)" />
                  </div>
                </div>
                <div v-else>
                  <div class="pt-5"><div class="col-span-12 items-center w-full h-12"><LoadingIcon icon="grid" class="w-12 h-12" /> </div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-7 xxl:col-span-9">
        <!-- datasets -->
        <div v-for="(dataset) in datasets" :key="dataset.id">
            <div class="intro-y box w-full" v-if="dataset.id == curDataset">
              <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 class=" text-base mr-auto"><ListIcon class="w-4 h-4 mr-1" /> {{$t('Dataset')}} <a href="javascript:;" data-toggle="modal" data-target="#dataset-info-box" class="mr-1 mb-2"><InfoIcon class="w-4 h-4 mr-1" /></a></h2>
              </div>
              <div class="preview p-5">

                <!-- Import loading -->
                <div v-if="ImportLog.length && !dataset.exampleCount" class="mb-5">
                   <div class="alert alert-outline-secondary alert-dismissible show flex items-center mb-2" role="alert">
                        <LoadingIcon icon="three-dots" class="w-6 h-6 mr-2" /> {{$t('Image import in progress. Please wait')}}...
                        <a href="javascript:;" data-toggle="modal" data-target="#dataset-import-box" class="pl-2 font-bold">{{$t('Details')}}</a>
                    </div>
                </div>

                <!-- details -->
                <div class="text-base font-bold mb-3">{{$t('Details')}}</div>
                <table class="table table--sm">
                    <tbody>
                        <tr class="hover:bg-gray-200"><td class="border">{{$t('Name')}}</td><td class="border font-bold">{{dataset.displayName}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">{{$t('Set ID')}}</td><td class="border">{{dataset.id}}</td></tr>
                        <tr class="hover:bg-gray-200">
                          <td class="border">{{$t('Total number of images')}} </td>
                          <td class="border" :class="dataset.exampleCount ? '' : 'bg-red-200'">
                            <div class="flex flex-col sm:flex-row">
                              <div class="font-bold" :class="dataset.exampleCount || ImportLog.length ? '' : 'pt-1'">{{ this.helper.formatScore(dataset.exampleCount) }}</div>
                              <div class="ml-auto" :class="dataset.exampleCount || ImportLog.length ? 'hidden' : ''">
                                <a href="javascript:;" data-toggle="modal" data-target="#dataset-csv-box" @click="importCSV(dataset.id)" class="pl-2 font-bold">
                                  <button type="button" class="btn btn-primary py-1 px-2"><FolderPlusIcon class="w-4 h-4 mr-2" /> {{$t('Import')}}</button>
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="hover:bg-gray-200"><td class="border">{{$t('Creation date')}}</td><td class="border">{{dataset.createTime.seconds}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">ETag</td><td class="border">{{dataset.etag}}</td></tr>
                        <tr class="hover:bg-gray-200"><td class="border">{{$t('Set metadata')}}</td><td class="border font-medium">{{dataset.datasetMetadata}}</td></tr>
                        <tr class="hover:bg-gray-200" v-if="dataset.imageClassificationDatasetMetadata"><td class="border">{{$t('Classification type')}}</td><td class="border">{{dataset.imageClassificationDatasetMetadata.classificationType}}</td></tr>
                        <tr class="hover:bg-gray-200" v-if="dataset.imageObjectDetectionDatasetMetadata"><td class="border">imageObjectDetectionDatasetMetadata</td><td class="border">{{dataset.imageObjectDetectionDatasetMetadata}}</td></tr>
                    </tbody>
                 </table>

                 <!-- dataset models -->
                 <div ref="DatasetModelsBox">
                  <div class="text-base font-bold mt-8 mb-3">{{$t('Models')}}</div>
                  <div v-if="DatasetModels.length">
                    <div v-for="(model) in DatasetModels" :key="model.modelID">
                      <div class="intro-y box">
                          <table class="table table--sm">
                              <tbody>
                                  <tr class="hover:bg-gray-200 cursor-pointer" v-on:click="curDataset=false; curModel=model.modelID">
                                    <td class="border">{{ model.modelID }}</td>
                                    <td class="border">{{ model.displayName }}</td>
                                    <td class="border">{{ model.deploymentState }}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="TrainingLog.length">
                        <div class="alert alert-outline-secondary alert-dismissible show flex items-center mb-2" role="alert">
                            <LoadingIcon icon="three-dots" class="w-6 h-6 mr-2" /> {{$t('Training model')}} <span class="font-bold pl-1">{{TrainingLog[0].payload.displayname}}</span>. {{$t('Wait')}} ...
                        </div>
                        <div class="text-xs pt-2">
                          {{$t('Initiated')}} {{ this.helper.getTimestampDate(TrainingLog[0].createdAt.toDate(),'date') }} 
                          {{$t('at')}} {{ this.helper.getTimestampDate(TrainingLog[0].createdAt.toDate(),'time') }}h
                        </div>
                    </div>
                    <div v-else>
                      {{$t('The dataset has not been trained')}}.<br>
                      {{$t('In order to create a custom model, you must first import a set of images to train it')}}. 
                      <div class="mt-5" v-if="ImportLog.length && dataset.exampleCount">
                        <a href="javascript:;" @click="TrainingDataset(dataset.id)" data-toggle="modal" data-target="#dataset-training-box" class="font-bold">
                            <button type="button" class="btn btn-dark py-2 px-4" ><CpuIcon class="w-4 h-4 mr-1" /> {{$t('Train model')}}</button>
                        </a>
                      </div>
                    </div>
                  </div>
                 </div>
                <!-- dataset info -->
                <div id="dataset-info-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-header p-5"><h2 class="font-medium text-base mr-auto">{{$t('Dataset')}}</h2></div>
                            <div class="modal-body">
                              <span class="font-bold">{{$t('When you update a dataset or its schema information')}}</span>, {{$t('you influence any future models that use that dataset')}}.{{$t('Models that have already started training will not be affected')}}.
                            </div>
                        </div>
                    </div>
                 </div>

                <!-- dataset Import Csv -->
                 <div id="dataset-csv-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true" v-if="!dataset.exampleCount">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header p-5"><h2 class="font-medium text-base mr-auto">{{$t('Select files to import')}}</h2></div>
                            <div class="modal-body" v-if="ImportForm">
                              <form ref="importCsv" class="validate-form" @submit.prevent="importDataset('importCsv',$event)">
                                <input type="hidden" name="datasetId" :value="dataset.id">
                                <div class="text-xs pt-2">
                                    {{$t('In order to create a custom model, you must first import a set of images to train it')}}. {{$t('Each image must be categorized with a tag')}}. {{$t('Tags are essential to tell the model how to identify an image')}}.<br><br>
                                    <span class="font-medium">{{$t('Each label must have at least 100 images to achieve best results')}}.</span>
                                </div>
                                <div class="mt-4">
                                    <div class="form-check mt-2">
                                        <input class="form-check-input" type="radio" name="csv" value="csv" checked disabled/>
                                        <label class="form-check-label" for="radio-switch-1">{{$t('Select a CSV file in Cloud Storage')}}</label>
                                    </div>
                                    <input ref="datasetcsv" type="text" name="datasetcsv" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10 mt-2" placeholder="gs://" required />
                                    <div class="mt-2">
                                      <div ref="apicsvload" class="hidden">
                                        <div class="alert alert-outline-secondary alert-dismissible show flex items-center mb-2" role="alert">
                                            <LoadingIcon icon="three-dots" class="w-6 h-6 mr-2" /> {{$t('Obtaining CSV')}} ...
                                        </div>
                                      </div>
                                      <div ref="apicsvlink">
                                        <span class="text-xs mt-2 text-blue-500 cursor-pointer" @click="getApiCsv()">{{$t('Get CSV from API')}} (/training/dataset)</span>
                                      </div> 
                                    </div>
                                    <button type="submit" class="btn btn-primary w-32 mt-3">{{$t('Continue')}}</button>
                                    <div class="text-xs mt-5">
                                      {{$t('Upload the files to')}} <a href="https://console.cloud.google.com/storage/browser" target="_blank" class="text-blue-500">Cloud Storage</a>.<br>
                                      {{$t('The CSV file should be a list of GCS paths to your images')}}.<br>
                                      {{$t('Images can be in JPG, PNG, GIF, BMP or ICO formats')}}.<br>
                                      {{$t('Optionally, you can specify the TRAIN, VALIDATION or TEST')}}.
                                    </div>
                                </div>
                              </form>
                            </div>
                        </div>
                    </div>
                 </div>
                 
                 <!-- Dataset import details -->
                 <div id="dataset-import-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true" v-if="ImportLog.length && !dataset.exampleCount">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <div class="modal-header p-5"><h2 class="font-medium text-base mr-auto">{{$t('Import')}}</h2></div>
                            <div class="modal-body">
                              {{$t('Set')}}:<br />  <span class="font-bold">{{dataset.id}}</span><br /><br />  
                              {{$t('Start date')}}:<br /><span class="font-bold">{{ this.helper.getTimestampDate(ImportLog[0].createdAt.toDate(),'date') }}</span><br /><br /> 
                              {{$t('Hour')}}:<br /><span class="font-bold">{{ this.helper.getTimestampDate(ImportLog[0].createdAt.toDate(),'time') }}</span><br /><br /> 
                              {{$t('CSV in Cloud Storage')}}:<br /><span class="font-bold">{{ImportLog[0].payload.csv}}</span><br /><br />
                              {{$t('Status')}}:<br /><span class="font-bold">{{$t('In progress')}}: {{$t('Importing images')}}</span><br />
                              <button class="btn btn-secondary w-24 mt-8 mb-2" @click="LoadDataset()">
                                  {{$t('Update')}}
                              </button>
                            </div>
                        </div>
                    </div>
                 </div>

                 <!-- dataset training (create model) -->
                 <div id="dataset-training-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true" v-if="!DatasetModels.length && TrainingForm && dataset.exampleCount">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-header p-5"><h2 class="font-medium text-base mr-auto">{{$t('Train')}} </h2></div>
                            <div class="modal-body">
                               <div ref="trainingmodelload" class="hidden">
                                  <div class="alert alert-outline-secondary alert-dismissible show flex items-center mb-2" role="alert">
                                      <LoadingIcon icon="three-dots" class="w-6 h-6 mr-2" /> {{$t('Training model')}} <span ref="trainingmodelloadtxt"></span>. {{$t('Wait')}} ...
                                  </div>
                                  <button class="btn btn-secondary w-24 mt-2 mb-2" @click="ChangeDataset(dataset.id)">{{$t('Update')}}</button>
                                </div>
                                <div ref="trainingmodelformload">
                                  <form ref="trainingForm" class="validate-form" @submit.prevent="training('trainingForm',$event)">
                                    <input type="hidden" name="datasetId" :value="dataset.id">
                                    <div class="text-xs pt-2">
                                        {{$t('Ideally, each tag should have at least 10 Images. Fewer Images are likely to result in inaccurate accuracy and recall scores')}}.<br><br> 
                                        {{$t('Also, you must have at least')}} <span class="font-bold">{{$t('8, 1 and 1 Images')}}</span> {{$t('Train')}}.<br>
                                    </div>
                                    <div class="relative text-gray-700 dark:text-gray-300 mt-5">
                                      <div class="text-xs pt-2 pb-1">{{$t('Train')}}</div>
                                      <input type="text" ref="modelname" name="modelname" class="form-control py-3 px-4 border-transparent bg-gray-200" 
                                        :placeholder="$t('Model name')" 
                                        :value="ImportLog[0].payload.csv ? dataset.displayName+'_'+ImportLog[0].payload.csv.match(/[^\/]+$/)[0].replace('.csv', '') : ''" :disabled="ImportLog[0].payload.csv" required />
                                    </div>
                                    <div class="relative text-gray-700 dark:text-gray-300 mt-3">
                                      <div class="text-xs pt-2 mb-2">
                                        {{$t('The budget for the creation of this model, expressed in milli node hours, that is, the value of 1000 in this field means 1 node hour')}} 
                                      </div>
                                      <input type="text" ref="modeltrainbudget" name="modeltrainbudget" class="form-control py-3 px-4 border-transparent bg-gray-200" placeholder="Ej;1000"
                                        value="1000" required />
                                    </div>
                                    <br>
                                    <button type="submit" class="btn btn-primary py-3 px-4"><CpuIcon class="w-4 h-4 mr-1" />{{$t('Train')}}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>

              </div>
            </div>
        </div> 
        <!-- Models -->
        <div :class="curModel ? '' : 'hidden'">
          <div v-for="(model) in models" :key="model.modelID">
              <div class="intro-y box " v-if="model.modelID == curModel">
                  <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                    <h2 class="font-medium text-base mr-auto"><CpuIcon class="w-4 h-4 mr-1" /> {{ model.displayName }}</h2>
                    <div class="ml-auto" :class="ModelEvaluation ? 'hidden' : ''"><button type="button" class="btn btn-dark py-2 px-4" @click="getEvaluation(model.modelID)"><CodepenIcon class="w-4 h-4 mr-1" /> {{ $t('Evaluate model') }}</button></div>
                  </div>
                  <div class="p-5">
                    <div class="preview pb-5">
                      <!-- Model Evaluation -->
                      <div :class="ModelEvaluation ? '' : 'hidden'" class="mb-5">
                         <div class="text-base pb-3 font-bold">{{$t('Model evaluation')}}</div>
                         <div div class="inline-block align-top pr-20 w-64"> 
                            <div class="text-base">{{$t('Confidence limit')}}</div>
                              <select class="form-select mt-2 sm:mr-2 cursor-pointer bg-gray-600 font-bold text-white w-40" v-if="ModelEvaluation[0] && ModelEvaluation[0].metrics.precisionThreshold" @change="setConfidenceLimit($event)">
                                  <option v-for="(t,k) in ModelEvaluation[0].metrics.precisionThreshold" v-bind:key="k" :selected="k==confidenceLimit">{{k}}</option>
                              </select>
                              <div class="text-xs pt-2">{{$t('Modify the limit to see which confidence threshold works best for your model')}}.</div>
                              <br>
                              <a href="javascript:;" data-toggle="modal" data-target="#model-info-box" class="mr-1 mb-2"><InfoIcon class="w-4 h-4 mr-1" />{{ $t('More information') }}</a>
                          </div>
                          <div div class="inline-block align-top"> 
                            <table class="table table--sm mb-5">
                              <tbody>
                                  <tr class="hover:bg-gray-200">
                                    <td></td>
                                    <td class="border">{{$t('Efficiency')}}</td>
                                    <td class="border">{{$t('Precision')}}</td>
                                    <td class="border">{{$t('Recovery')}}</td>
                                    <td class="border">{{$t('Total images')}}</td>
                                    <td class="border">{{$t('elements')}}<br>{{$t('test')}}</td>
                                  </tr>
                                  <tr class="hover:bg-gray-200" v-for="me in ModelEvaluation" v-bind:key="me.id">
                                    <td class="border"><span v-if="me.displayName">{{ me.displayName }}</span><span v-else>{{$t('All tags')}}</span></td>
                                    <td class="border text-center" v-if="me.metrics && me.metrics.auPrc" :class="me.metrics.auPrc==1 ? 'bg-green-100' : 'bg-gray-200'">
                                      {{ me.metrics.auPrc }}
                                    </td>
                                    <td class="border text-center" v-if="me.metrics.precisionThreshold[confidenceLimit]" :class="me.metrics.precisionThreshold[confidenceLimit]==100 ? 'bg-green-100' : 'bg-gray-200'">
                                      {{me.metrics.precisionThreshold[confidenceLimit]}}%
                                    </td><td v-else class="border">-</td>
                                    <td class="border text-center" v-if="me.metrics.recallThreshold[confidenceLimit]" :class="me.metrics.recallThreshold[confidenceLimit]==100 ? 'bg-green-100' : 'bg-gray-200'">
                                      {{me.metrics.recallThreshold[confidenceLimit]}}%
                                    </td><td v-else class="border">-</td>
                                    <td class="border text-center">
                                      <span v-if="model.dataset">
                                        <span v-if="model.dataset.exampleCount && me.evaluatedExampleCount">
                                          {{ this.helper.formatScore(model.dataset.exampleCount-me.evaluatedExampleCount) }}
                                        </span>
                                      </span>
                                    </td>
                                    <td class="border text-center">{{ this.helper.formatScore(me.evaluatedExampleCount) }}</td>
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                          <div class="mt-2" v-if="DataMatrix">
                              <div class="text-base pb-2 font-bold">{{ $t('Confusion matrix') }}</div>
                              <div class="text-xs">{{$t('This table shows how often the model classified')}}<br />{{$t('each label correctly')}} ({{$t('on')}} <span class="text-blue-500">{{$t('color blue')}}</span>) {{$t('on')}}<br />{{$t('most often confused with that label')}} ({{$t('on')}} <span class="text-gray-500">{{$t('color gray')}}</span>).</div>
                              <table class="table table--sm mb-5 w-full lg:w-2/3 mt-2 mb-10">
                                <tbody>
                                    <tr class="hover:bg-gray-200">
                                      <td class="w-40"></td>
                                      <td :colspan="DataMatrix.displayName.length"><span class="text-gray-500">{{$t('Predicted label')}}</span></td>
                                    </tr>
                                    <tr class="hover:bg-gray-200">
                                      <td class="w-40"><span class="text-gray-500">{{$t('Trust tag')}}</span></td>
                                      <td class="text-center font-bold datamatrixColHeader" v-for="name in DataMatrix.displayName" v-bind:key="name">{{name}}</td>
                                    </tr>
                                    <tr class="hover:bg-gray-200" v-for="(name,idx1) in DataMatrix.displayName" v-bind:key="name">
                                      <td class="border text-center w-40">{{name}}</td>
                                      <td v-for="(val,idx2) in DataMatrix.row[idx1].exampleCount" v-bind:key="val" class="border text-center" 
                                        :class="!val ? '' : idx2==idx1 ? 'bg-blue-500 text-white' : 'bg-gray-500'">
                                        <span v-if="val==0">-</span><span v-else>
                                          {{ this.helper.formatScore(val) }}
                                        </span>
                                        <br>
                                        <span class="text-xs" v-if="val">{{ Math.round(((val / DataMatrix.rowsCount[idx1]) * 100).toFixed(2)) }}%</span>
                                      </td>
                                    </tr>
                                  </tbody>
                              </table>
                          </div> 
                          <div id="model-info-box" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
                              <div class="modal-dialog modal-sm">
                                  <div class="modal-content">
                                      <div class="modal-header p-5">
                                          <h2 class="font-medium text-base mr-auto">{{$t('Model evaluation')}}</h2>
                                      </div>
                                      <div class="modal-body">
                                          <div class="text-xs"><span class="font-bold">{{$t('Scoring threshold')}}</span><br />{{$t('The score threshold refers to the level of confidence that the model must have to assign a category to a test item')}}.<br><br> {{$t('If the score threshold is low, your model will classify more images, but you run the risk of misclassifying some images in the process')}}.<br><br> {{$t('If the scoring threshold is high, your model will classify fewer images, but have a lower risk of misclassifying images')}}.</div>
                                          <div class="text-xs mt-5"><span class="font-bold">{{$t('Precision')}}</span><br />{{$t('A high-precision model produces fewer false positives')}}.</div>
                                          <div class="text-xs mt-5"><span class="font-bold">{{$t('Recovery')}}</span><br />{{$t('A model with a high degree of recovery produces fewer false negatives')}}.</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="accordion mb-5">
                        <!-- Model Details -->
                        <div class="accordion-item">
                            <div id="accordion-content-1" class="accordion-header show">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-1" aria-expanded="true" aria-controls="accordion-collapse-1">
                                 <ChevronsRightIcon class="w-5 h-5 align-top" /> {{$t('Model details')}}
                                </button>
                            </div>
                            <div id="accordion-collapse-1" class="accordion-collapse collapse show" aria-labelledby="accordion-content-1" data-bs-parent="#accordion-1">
                                <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                                    <table class="table table--sm mt-5">
                                      <tbody>
                                          <tr class="hover:bg-gray-200" v-if="model.modelID"><td class="border">{{$t('Model ID')}}</td><td class="border font-medium">{{ model.modelID }}</td></tr>
                                          <tr class="hover:bg-gray-200" v-if="model.dataset && model.dataset.displayName">
                                            <td class="border">{{$t('Dataset')}}</td>
                                            <td class="border font-medium">
                                              <div class="cursor-pointer" v-on:click="curModel=false; curDataset=model.dataset.id">{{ model.dataset.displayName }}</div>
                                            </td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.name">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  :content="$t('Format: projects / {project_id} / locations / {locationId} / models / {modelId}')">
                                                  {{$t('Model resource name')}}
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.name }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.displayName">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  :content="$t('The name of the model that is displayed in the interface. The name can be up to 32 characters long and can only consist of ASCII Latin letters A-Z and a-z, underscores (_), and ASCII digits 0-9. It must start with a letter')">
                                                  {{$t('Internal name')}}
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.displayName }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.createTime">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  :content="$t('Timestamp when model training finished and can be used for prediction')">
                                                  {{$t('Creation date')}}
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ this.helper.getUnixDate(model.createTime.seconds) }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.updateTime">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  :content="$t('Timestamp of the last update of this model')">
                                                  {{$t('Update date')}}
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.updateTime.seconds }} {{ this.helper.getUnixDate(model.updateTime.seconds) }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.deploymentState">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  :content="$t('Can only serve prediction requests after deployment')">
                                                  {{$t('Model implementation status')}}
                                              </Tippy>
                                            </td>
                                            <td class="border font-medium">{{ model.deploymentState }}</td>
                                          </tr>
                                          <tr class="hover:bg-gray-200" v-if="model.modelMetadata">
                                            <td class="border">
                                              <Tippy tag="a" href="javascript:;"
                                                  :content="$t('It must match the metadata type of the dataset used to train the model')">
                                                  {{$t('Model metadata')}}
                                              </Tippy>
                                            </td>
                                            <td class="border font-normal">
                                              <div v-if="model.modelMetadata=='imageClassificationModelMetadata'" class="font-medium">Metadata for image classification models.</div>
                                              {{ model.modelMetadata }}
                                            </td>
                                          </tr>
                                      </tbody>
                                  </table>
                                </div>
                            </div>
                        </div>
                        <!-- Model Metadata -->
                        <div class="accordion-item" v-if="model.imageClassificationModelMetadata">
                            <div id="accordion-content-2" class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion-collapse-2" aria-expanded="false" aria-controls="accordion-collapse-2">
                                   <ChevronsRightIcon class="w-5 h-5 align-top" /> {{$t('Metadata for image classification')}}
                                </button>
                            </div>
                            <div id="accordion-collapse-2" class="accordion-collapse collapse" aria-labelledby="accordion-content-2" data-bs-parent="#accordion-1">
                                <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                                   <table class="table table--sm mt-5">
                                    <tbody>
                                        <tr class="hover:bg-gray-200">
                                          <td class="border w-40"><Tippy tag="a" href="javascript:;" content="baseModelId">{{$t('Base model ID')}}</Tippy></td>
                                          <td class="border font-medium"><span v-if="model.imageClassificationModelMetadata.baseModelId">{{ model.imageClassificationModelMetadata.baseModelId }}</span><span v-else>{{$t('None')}}</span></td>
                                          <td class="border">
                                          <span class="text-xs">{{$t('If specified, the new model will be created based on the base model')}}.<br>{{$t('Otherwise the new model will be created from scratch')}}.<br>{{$t('The base model must be in the same project and location as the new model to create, and have the same model type')}}.</span>
                                          </td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.stopReason">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="stopReason">{{$t('Reason for stop')}}</Tippy></td>
                                          <td class="border font-medium">{{ model.imageClassificationModelMetadata.stopReason }}</td>
                                          <td class="border">
                                          <span class="text-xs">{{$t('The reason this model build operation stopped')}}.</span>
                                          </td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.modelType">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="modelType">{{$t('Model type')}}</Tippy></td>
                                          <td class="border font-medium">
                                            <span v-if="model.imageClassificationModelMetadata.modelType=='mobile-high-accuracy-1'">
                                              <Tippy tag="a" href="javascript:;" :content="model.imageClassificationModelMetadata.modelType">{{$t('Greater accuracy on mobile device')}}</Tippy>
                                            </span>
                                            <span v-else>{{ model.imageClassificationModelMetadata.modelType }}</span>
                                          </td>
                                          <td class="border">
                                          <span class="text-xs" v-if="model.imageClassificationModelMetadata.modelType=='mobile-high-accuracy-1'">
                                            {{$t('This is a model that, in addition to providing predictions via the AutoML API, can also be exported (see AutoMl.ExportModel) and then used on a mobile or edge device with TensorFlow. It is expected to have higher latency, but it should also have higher prediction quality than other models')}}
                                          </span>
                                          <span class="text-xs" v-if="model.imageClassificationModelMetadata.modelType=='cloud'">
                                            {{$t('Model to be used by prediction calls to the AutoML API. This is the default value')}}. 
                                          </span>
                                          </td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.nodeQps">
                                          <td class="border">NodeQps</td>
                                          <td class="border font-medium">{{ model.imageClassificationModelMetadata.nodeQps }}</td>
                                          <td class="border"><span class="text-xs">{{$t('Approximate number of online prediction QPS this model can support for each node where it is deployed')}}.</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.nodeCount">
                                          <td class="border">nodeCount</td>
                                          <td class="border font-medium">{{ model.imageClassificationModelMetadata.nodeCount }}</td>
                                          <td class="border"><span class="text-xs">{{$t('The number of nodes in which this model is implemented')}}.<br /> {{$t('A node is an abstraction of a machine resource, which can handle the QPS online prediction as indicated in the nodeQps field')}}.</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.trainBudgetMilliNodeHours">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="trainBudgetMilliNodeHours">{{$t('Budget to')}}<br /> {{$t('creating this model')}}</Tippy></td>
                                          <td class="border font-normal">
                                            <div class="font-medium">{{ Number.parseFloat(model.imageClassificationModelMetadata.trainBudgetMilliNodeHours/1000).toFixed(2) }} {{$t('Node hours')}}</div>
                                            <span class="text-xs">{{$t('Value')}}: {{ model.imageClassificationModelMetadata.trainBudgetMilliNodeHours }}</span>
                                          </td>
                                          <td class="border"><span class="text-xs">{{$t('The train budget for the creation of this model, expressed in milli node hours, that is, the value 1000 in this field means 1 node hour. The actual train cost will be equal to or less than this value')}}.<br />{{$t('If the additional training of the model stops providing improvements, it will be stopped without using the full budget and the reason for the stop will be MODEL_CONVERGED')}}.</span></td>
                                        </tr>
                                        <tr class="hover:bg-gray-200" v-if="model.imageClassificationModelMetadata.trainCostMilliNodeHours">
                                          <td class="border"><Tippy tag="a" href="javascript:;" content="trainCostMilliNodeHours">{{$t('Real cost')}}</Tippy></td>
                                          <td class="border font-normal">
                                            <div class="font-medium">{{ Number.parseFloat(model.imageClassificationModelMetadata.trainCostMilliNodeHours/1000).toFixed(2) }} {{$t('Node Processing Hours')}}</div>
                                            <span class="text-xs">{{$t('Value')}}: {{ model.imageClassificationModelMetadata.trainCostMilliNodeHours }}</span>
                                          </td>
                                          <td class="border"><span class="text-xs">{{$t('The actual train cost of creating this model, expressed in milli node hours, that is, the value of 1,000 in this field means 1 node hour. Guaranteed not to exceed the train budget')}}.</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { model as $model } from "@/model/model";
import { helper as $h } from "@/utils/helper";
import { event as $event } from "@/model/events";
import { useStore } from "@/store";

export default defineComponent({
  created () {
    this.helper = $h
  },  
  setup() {

    const models     = ref([])
    const datasets   = ref(false)
    const curModel   = ref()
    const curDataset = ref()
    const DataMatrix = ref(false)
    const createDataset   = ref(false);
    const createModel     = ref(false);
    const confidenceLimit = ref(0.5)
    const ModelEvaluation = ref(false)
    const ImportForm      = ref(false)
    const ImportLog       = ref(false)
    const TrainingForm    = ref(false)
    const TrainingLog     = ref(false)
    const DatasetModels   = ref([])

    const ChangeModel = (m) => {  curModel.value = m; confidenceLimit.value = 0.5; ModelEvaluation.value = false;  ModelEvaluation.value = false; DataMatrix.value = false;};
    const ChangeDataset = async (d) => { 
      curDataset.value = d; ImportForm.value = TrainingForm.value = false; await checkImport(); await checkTraining(); await getDatasetModels(curDataset.value)
    }
    
    const getEvaluation = async (modelID) => { 
      ModelEvaluation.value = await $model.getEvaluation(modelID); 
      if(ModelEvaluation.value)DataMatrix.value = await $model.getEvaluationDatamatrix(ModelEvaluation.value)
    };
    
    const setConfidenceLimit = (e) => { confidenceLimit.value = e.target.value }
    const importCSV = () => { ImportForm.value = true; }
    const TrainingDataset = () => { TrainingForm.value = true; }
    
    const LoadDataset = async () => {
      datasets.value = await $model.getDataset(); if(datasets.value[0])curDataset.value = datasets.value[0].id 
      await getDatasetModels(curDataset.value)
      await checkImport()
      await checkTraining()
    }
    const LoadModels = async () => {
      models.value   = await $model.get(); 
    }
    const getDatasetModels = async (datasetId) => {
      DatasetModels.value = [];
      for (let i = 0; i < models.value.length; i++) {
        if(models.value[i].dataset.id==datasetId)DatasetModels.value.push(models.value[i])
      }
    }
    const checkImport = async () => {
      ImportLog.value = await $event.get({ type: "dataset.import" , dataset: curDataset.value})
    }
    const checkTraining = async () => {
      TrainingLog.value = await $event.get({ type: "dataset.training" , dataset: curDataset.value})
    }
    onMounted( async () => { 
      await LoadDataset()
      await LoadModels();
    });

    return {
      models,
      datasets,
      curModel,
      curDataset,
      createDataset,
      createModel,
      confidenceLimit,
      DataMatrix,
      ImportForm,
      importCSV,
      ImportLog,
      ChangeModel,
      ChangeDataset,
      LoadDataset,
      TrainingForm,
      TrainingDataset,
      TrainingLog,
      ModelEvaluation,
      getEvaluation,
      setConfidenceLimit,
      DatasetModels
    };
  },
  methods:{
    newDataset: function(){ this.createDataset = true; },
    closeAddDataset: function(){ this.createDataset = false; },
    addDataset: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let DisplayName = "";
        let Type = ""
        for (let i = 0; i < this.$refs[formID].length; i++) {  
          if(this.$refs[formID][i].name=="dsname")DisplayName = this.$refs[formID][i].value.toLowerCase();
          if(this.$refs[formID][i].name=="dstype")Type = this.$refs[formID][i].value
        }  
        if(DisplayName){
           await $model.addDataset(DisplayName,Type).then(async response =>  {
            if(response.name){
              let newDatasetID = response.name.split('/')[5]; 
              $h.NotificationTxt({ text: this.$i18n.t('The dataset has been created')+': '+newDatasetID});
              this.datasets    = await $model.getDataset();  
              if(newDatasetID){
                this.curDataset = newDatasetID
                await $event.saveEvent('dataset.create', { uid: useStore().state.main.User.uid, dataset: newDatasetID }, false)
                window.location.reload()
              } 
            }
          })
        }
      }
    },
    importDataset: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let DatasetCsvPath = "";
        let datasetId = "";
        for (let i = 0; i < this.$refs[formID].length; i++) {  
          if(this.$refs[formID][i].name=="datasetcsv")DatasetCsvPath = this.$refs[formID][i].value.toLowerCase();
          if(this.$refs[formID][i].name=="datasetId")datasetId = this.$refs[formID][i].value;
        }  
        if(DatasetCsvPath && datasetId){
           await $model.importDatasetCsv(DatasetCsvPath,datasetId).then(async response =>  {
            if(response){
             $h.NotificationTxt({ text: this.$i18n.t('Image set import started successfully') });
             await $event.saveEvent('dataset.import', { uid: useStore().state.main.User.uid, dataset: datasetId, csv: DatasetCsvPath }, false)
             this.ChangeDataset(datasetId)
            }
          })
        }
      }
    },
    training: async function(formID,event){
      if (event) event.preventDefault()
      if(this.$refs[formID].length){
        let Modelname = "";
        let datasetId = "";
        let trainBudget = "1000";
        for (let i = 0; i < this.$refs[formID].length; i++) {  
          if(this.$refs[formID][i].name=="datasetId")datasetId = this.$refs[formID][i].value;
          if(this.$refs[formID][i].name=="modelname")Modelname = this.$refs[formID][i].value.toLowerCase();
          if(this.$refs[formID][i].name=="modeltrainbudget")trainBudget = this.$refs[formID][i].value;
        }  
        if(Modelname && datasetId){
          this.$refs["trainingmodelload"].classList.value = ''
          this.$refs["trainingmodelformload"].classList.value = ['hidden']
          this.$refs["DatasetModelsBox"].classList.value = ['hidden']
          let res = await $model.trainingDataset(datasetId,Modelname,trainBudget)
          if(res.operation){
            this.$refs["trainingmodelloadtxt"].innerText = res.operation.name
            this.$refs["trainingmodelloadtxt"].classList.value = ['pl-1']
          }
          await $event.saveEvent('dataset.training', { uid: useStore().state.main.User.uid, dataset: datasetId, displayname: Modelname }, false)
          console.log(datasetId+" --> "+Modelname)
          console.log(res)
        }
      }
    },
    getApiCsv: async function(){
      this.$refs["apicsvload"].classList.value = ''
      this.$refs["apicsvlink"].classList.value = ['hidden']
      let res = await $model.generateDataset()
      if(this.$refs["datasetcsv"] && res.training)this.$refs["datasetcsv"].value = res.training.dataset 
      this.$refs["apicsvload"].classList.value = ['hidden']
      this.$refs["apicsvlink"].classList.value = ''
    }
  }
});
</script>
<style>
.datamatrixColHeader{transform: translateX(0) translateY(-5%) rotate(-45deg) !important; width:70px;}
</style>
