<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-3 xxl:col-span-2 flex lg:block flex-col-reverse" v-if="CurTab!='ManageModels'">
        <div class="intro-y box mt-5 lg:mt-0 pb-5">
          <div class="relative flex items-center p-5">
            <div class="w-14 h-14 image-fit"><CodesandboxIcon class="w-14 h-14"  /></div>
            <div class="ml-3 mr-auto"><div class="font-medium text-base">{{ $t('Settings') }}</div><div class="text-gray-600">{{ $t('General') }}</div></div>
          </div>
          <div v-for="(tab) in Tabs" :key="tab.id"
           :class="tab.roles ? User.role && tab.roles.includes(User.role.role_id) ? 'py-2 px-5 border-t border-gray-200 dark:border-dark-5' : 'hidden': 'py-2 px-5 border-t border-gray-200 dark:border-dark-5'">
            <a class="flex items-center cursor-pointer" 
              :class="tab.roles ? User.role && tab.roles.includes(User.role.role_id) ? TabActive(tab.id) : 'hidden': TabActive(tab.id)" 
              @click="ChangeTab(tab.id)">
              <component :is="tab.icon" class="w-6 h-6 mr-2"  /> {{ tab.name }} 
            </a>
          </div>
        </div>
        <div class="intro-y box mt-5 lg:mt-0 cursor-pointer" @click="linkTo('doc')" v-if="version">
          <div class="col-span-12 lg:col-span-6 mt-6">
            <div class="ads-box box p-5 relative overflow-hidden intro-y">
              <div class="ads-box__title w-full sm:w-52 text-theme-17 dark:text-white text-base -mt-3">{{ $t('User guide and') }} <span class="font-medium">{{ $t('Documentation') }}</span></div>
              <div class="w-full sm:w-60 leading-relaxed text-gray-600 mt-2">v.{{ version }}</div>
              <img class="hidden sm:block absolute top-0 right-0 w-40 mt-12 -mr-8 opacity-40" :alt="$t('Documentation')" :src="require(`@/assets/images/logo/rphelp.png`)" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-else class="col-span-12">
        <button type="button" class="btn btn-outline-secondary w-fill" @click="ChangeTab(Tabs[0].id)">Volver</button>
      </div> -->
      <div class="col-span-12" :class="CurTab=='ManageModels' ? 'lg:col-span-12 xxl:col-span-12' : 'lg:col-span-9 xxl:col-span-10'"><div v-if="CurTab"><component :is="CurTab" ref="ManageTagsRef" /></div></div>
    </div>   
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref,  } from "vue"; //useContext
import { view as $view } from "@/model/view";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

import ManageApp      from "@/components/manage-app/Main.vue";
import ManageTags     from "@/components/manage-tags/Main.vue";
import ManageEvents   from "@/components/manage-events/Main.vue";
import ManageModels   from "@/components/manage-models/Main.vue";
import ManageUsers    from "@/components/manage-users/Main.vue";
import ManageMonitors from "@/components/manage-monitors/Main.vue";
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    ManageTags,
    ManageEvents,
    ManageModels,
    ManageUsers,
    ManageApp,
    ManageMonitors
  },
  setup() { //context

    const store      = useStore();   
    const User       = computed(() => store.state.main.User);
    const router     = useRouter();
    const {t}        = useI18n(); 
    const Tabs       = ref([
                          {id: "ManageApp",       name: t('App'),       icon: "BoxIcon",         roles: "2,3" },
                          {id: "ManageMonitors",  name: t('Monitors'),  icon: "MonitorIcon",     roles: "3" },
                          {id: "ManageTags",      name: t('Tags'),      icon: "BookmarkIcon",    roles: "2,3" },
                          {id: "ManageModels",    name: t('Model'),     icon: "CpuIcon",         roles: "3" },
                          {id: "ManageEvents",    name: t('Events'),    icon: "ActivityIcon",    roles: "3" },
                          {id: "ManageUsers",     name: t('Users'),     icon: "UsersIcon",       roles: "3" },
                          ])
    const CurTab     = ref(Tabs.value[0].id)
    const TabActive  = (t) => { if(t==CurTab.value)return "dark:text-white text-theme-12 font-medium" };
    const ChangeTab  = (t) => { CurTab.value = t;};
    const ReloadTab  = (t) => { CurTab.value = false; setInterval(() => { CurTab.value = t;}, 1000);  };
    const version    = ref()

    const linkTo = (page) => {  router.push({ name: page  });  };

    onMounted( async () => { 
      version.value = await $view.getSettings('settings','version');
      //useContext().expose({ CurTab, Tabs, ChangeTab })
      //if(context.tab)CurTab.value = context.tab;
    });

    return {
      User,
      Tabs,
      CurTab,
      TabActive,
      ChangeTab,
      ReloadTab,
      linkTo,
      version,
      t
    };
  }
});
</script>